import {
    IFixedDownloadButtonProps,
    IHamburgerComponentProps,
    IHeaderComponentProps
} from "../../../lib/types/components";
import { publishWithUsType, sendPublishWithUsEvent } from "../../../lib/utils/constants/AnalyticsConstants";
import {
    GDC_HEADER_TYPE,
    GDC_WEB_URL,
    GET_APP_AF_URL,
    GET_APP_FB_URL,
    GET_APP_URL,
    HEADER_DOWNLOAD_BUTTON_TYPE
} from "../../../lib/utils/constants/GlobalConstants";
import {
    currentPageURL, dynamicCssSize
} from '../../../lib/utils/constants/PagesConstants';
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import FixedDownloadButtonV2 from "../FixedDownloadButton/FixedDownloadButtonV2";
import GDCSubmitButton from "../FixedDownloadButton/GDCSubmitButton";
import HamburgerComponent from "../Hamburger/HamburgerComponent";
import LanguageToggleComponent from "../LanguageToggle/LanguageToggleComponent";
import React from "react";

const HeaderComponentV2 = (props: IHeaderComponentProps) => {

    const {
        specialData,
        fixedDownloadButtonData,
        downloadLink,
        isApkDownloading,
        handleHamburgerClick,
        menuOpen,
        sendAnalytics,
        hideDownloadButton,
        setIsApkDownloading,
        setIsToastOpen,
        isUtmHotstar,
        languageToggleData,
        showToggle,
        locale,
        countrySwitcherText,
        abTestingData,
        appVersions,
        setAppsFlyerURL,
        headerType,
        hideSubmitButton,
        gdcFormUrl
    } = props;


    const hamburgerCompProps: IHamburgerComponentProps = {
        handleHamburgerClick: handleHamburgerClick,
        menuOpen: menuOpen
    }

    const hideHamburger: boolean = props.hideHamburger || (currentPageURL === GET_APP_URL || currentPageURL === GET_APP_AF_URL || currentPageURL === GET_APP_FB_URL);

    const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
        apkUrl: downloadLink,
        isApkDownloading: isApkDownloading,
        type: HEADER_DOWNLOAD_BUTTON_TYPE,
        fixedDownloadButtonData: fixedDownloadButtonData,
        isUtmHotstar: isUtmHotstar,
        sendAnalytics: sendAnalytics,
        setIsApkDownloading: setIsApkDownloading,
        setIsToastOpen: setIsToastOpen,
        appVersions,
        abTestingData,
    }

    const toggleLanguageProps = {
        menuOpen: menuOpen,
        languageToggleData: languageToggleData,
        locale: locale,
        handleHamburgerClick: handleHamburgerClick
    }

    const isGetApp = currentPageURL === GET_APP_URL

    return (
        fixedDownloadButtonData ?
            <>
                <style jsx>{`
                  .header {
                    background-color: rgba(0, 0, 0, 0.9);
                    position: fixed;
                    top: 0;
                    width: 100%;
                    height: 51px;
                    z-index: 1000;
                    font-family: var(--font-primary);
                    display: flex;
                    align-items: center;
                  }

                  .nav {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: row;
                  }

                  .logoContainer {
                    display: flex;
                    align-items: center;
                  }

                  .logo {
                    position: relative;
                    width: ${dynamicCssSize(150, 93)};
                    height: 35px;
                  }

                  .nav a {
                    text-decoration: none;
                  }

                  .navItemsContainer {
                    display: flex;
                    align-items: center !important;
                  }

                  .navItems > ul {
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style: none;
                  }

                  .navItems > ul > li > a, .navItems span {
                    font-size: ${dynamicCssSize(18, 14)};
                    text-transform: uppercase;
                    transition: .3s;
                    display: block;
                    padding: .3rem 1rem;
                    font-weight: 600;
                    letter-spacing: -0.26px;
                    color: #fff;
                  }

                  .greenBackground {
                    position: relative;
                  }

                  .greenBackground:hover::after {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    right: 10px;
                    transform-origin: bottom left;
                    transform: skew(-30deg, 0deg);
                    z-index: -1;
                    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
                  }

                  .greenBackground:hover span {
                    color: black
                  }

                  .greenBackground > a {
                    color: white;
                  }

                  .headerButtonContainer {
                    display: none;
                  }

                  .dropbtn {
                    font-size: ${dynamicCssSize(18, 15)};
                    text-transform: uppercase;
                    transition: .3s;
                    display: block;
                    padding: .3rem 1rem;
                    font-weight: 600;
                    letter-spacing: -0.26px;
                    color: #fff;
                    cursor: pointer;
                  }

                  .dropdown {
                    position: relative;
                    display: inline-block;
                  }

                  .dropdownContent {
                    font-weight: 500;
                    font-size: 12px;
                    display: none;
                    border-radius: 2px;
                    position: absolute;
                    right: 0;
                    background-color: black;
                    min-width: 120px;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                    z-index: 1;
                  }

                  .dropdownContent a {
                    color: white;
                    padding: 10px;
                    text-decoration: none;
                    display: block;
                  }

                  .dropdownContent a:hover {
                    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
                    border-radius: 2px;
                    color: black;
                  }

                  .dropdown:hover .dropdownContent {
                    display: block;
                  }

                  .dropdown:hover::after .dropbtn {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 2px;
                    top: 0;
                    right: 10px;
                    transform-origin: bottom left;
                    transform: skew(-30deg, 0deg);
                    z-index: -1;
                    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
                  }

                  .languageToggle {
                    display: flex;
                    align-items: center;
                  }

                  @media screen and (max-width: 1024px) {
                    .navItems {
                      display: ${headerType===GDC_HEADER_TYPE ? "block" : "none"};
                    }

                    .drawer > div {
                      margin-left: 50%;
                    }
                  }

                  @media screen and (max-width: 576px) {
                    .drawer > div {
                      margin-left: 40%;
                    }

                    .navItems > ul > li > a, .navItems span {
                      padding: .3rem .25rem;
                      font-weight: 500;
                    }

                    .headerButtonContainer {
                      display: block
                    }

                    .languagetoggle {
                      width: 22px;
                      height: 22px;
                    }
                  }
                `}
                </style>
                <header className={"header"}>
                    <div className={"container"}>
                        <nav className={"nav"}>
                            <div className={"logoContainer"}>
                                <div className={"logo"}>
                                    {
                                        (currentPageURL && currentPageURL.includes(GET_APP_URL)) ? (
                                            <CustomImageComponent
                                                layout={"fill"}
                                                objectFit={"contain"}
                                                src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/logo-gold.png"
                                                alt="winzo games logo"
                                                priority={true}
                                            />
                                        ) : (
                                            headerType === GDC_HEADER_TYPE ?
                                                <a href={"https://console.winzogames.com/"}>
                                                    <CustomImageComponent
                                                        layout={"fill"}
                                                        objectFit={"contain"}
                                                        src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/logo-gold.png"
                                                        alt="winzo games logo"
                                                        priority={true}
                                                    />
                                                </a>
                                                :
                                                <CustomLinkComponent href={"/"}>
                                                    <CustomImageComponent
                                                        layout={"fill"}
                                                        objectFit={"contain"}
                                                        src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/logo-gold.png"
                                                        alt="winzo games logo"
                                                        priority={true}
                                                    />
                                                </CustomLinkComponent>
                                        )
                                    }
                                </div>
                                <div className={"languageToggle"}>
                                    {showToggle && languageToggleData &&
                                        <LanguageToggleComponent {...toggleLanguageProps} />}
                                </div>
                            </div>



                            <div className={"navItemsContainer"}>
                                <div className={"navItems"}>
                                    <ul>
                                        {

                                            (!menuOpen && !isGetApp && specialData) && specialData.map((item, index) => {
                                                    if (item.dropdown) {
                                                        return (
                                                            <li key={"specialData_" + index}>
                                                                <div className="dropdown" key={index}>
                                                                    <div className="dropbtn">{item.title}</div>
                                                                    <div className="dropdownContent">
                                                                        {item.dropdown.map((drop, i) => {
                                                                            return <a href={drop.link} key={"item.dropdown_" + i}>{drop.title}</a>
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    if (item.special && !item.dropdown) {
                                                        return (
                                                            <li key={index} className={"greenBackground"}>
                                                                {   
                                                                  item.outside
                                                                        ?
                                                                        <a href={item.link} target="_blank" rel={"noreferrer"} onClick={() => {
                                                                            item.link === GDC_WEB_URL && sendPublishWithUsEvent(publishWithUsType.DESKTOP)
                                                                        }}>
                                                                            <span>{item.title}</span>
                                                                        </a>
                                                                        :
                                                                        <CustomLinkComponent
                                                                            href={item.link}
                                                                            mobStyle={{
                                                                                textDecoration: "none"
                                                                            }}
                                                                            desktopStyle={{
                                                                                textDecoration: "none"
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                {item.title}
                                                                            </span>
                                                                        </CustomLinkComponent>
                                                                }
                                                            </li>
                                                        )

                                                    }
                                                    return <></>
                                                }
                                            )
                                        }
                                    </ul>
                                </div>
                                {
                                    hideDownloadButton
                                        ? <></>
                                        : <FixedDownloadButtonV2 {...fixedDownloadBtnProps} />
                                }
                                {headerType === GDC_HEADER_TYPE && !hideSubmitButton ? (
                                  <GDCSubmitButton
                                      text={"SUBMIT NOW"}
                                      url={gdcFormUrl}
                                      sendAnalytics={sendAnalytics}
                                      type={HEADER_DOWNLOAD_BUTTON_TYPE}
                                  />
                                ) : (
                                    <></>
                                )}
                                {
                                    !hideHamburger && <HamburgerComponent {...hamburgerCompProps} />
                                }
                            </div>
                        </nav>
                    </div>
                </header>
            </> : <></>
    )
}

export default HeaderComponentV2;