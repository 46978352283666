import { useEffect, useState } from "react";
import Analytics from "../../../lib/analytics/Analytics";
import { IdentifyLanguageClick, LanguageToggleClick } from "../../../lib/analytics/AnalyticsEvents";
import { ILanguageToggleData } from "../../../lib/types/common";
import { IToggleDataProps } from "../../../lib/types/components";
import {
    allLocales
} from "../../../lib/utils/constants/LocaleAndCountryConstants";
import { getCountryFromLocale, getUrlSegments, isMobile } from "../../../lib/utils/constants/PagesConstants";
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from "../CustomImage/CustomImageComponent";

const LanguageToggleComponent = (props: IToggleDataProps) => {

    const {menuOpen, handleHamburgerClick} = props;
    const languageToggleData: ILanguageToggleData = props.languageToggleData
    let {locale} = props;

    const [isToggleOpen, setIsToggleOpen] = useState(false);
    useEffect(() => {
        if (window) {
            document.addEventListener('scroll', () => setIsToggleOpen(false))
        }
        return document.removeEventListener('scroll', () => setIsToggleOpen(false))
    }, [])

    useEffect(() => {
        if (isToggleOpen && menuOpen) {
            setIsToggleOpen(false);
        }
    }, [menuOpen])

    //Not showing toggle for us for now as there is only one language
    const availableLanguages = languageToggleData.toggleData.availableLanguages.filter((countryLang) => {
        return getCountryFromLocale(locale) === getCountryFromLocale(countryLang.locale)
    });

    const toggleClick = () => {
        if (!isToggleOpen) {
            Analytics.getInstance().logEvent(LanguageToggleClick);
        }
        if (menuOpen) {
            handleHamburgerClick();
        }
        setIsToggleOpen(!isToggleOpen);
    }

    const segmentsArray = getUrlSegments();
    // trim path if locale is present
    let path = allLocales.includes(segmentsArray[0]) ? segmentsArray.slice(1).join('/') : segmentsArray.join('/');

    if (availableLanguages.length <= 1) return <></>;

    const getClassName = (language: string) => {
        const length = availableLanguages?.length;
        let classToggle = 'anchorTag';
        if (length < 4 && language === locale) {
            classToggle = 'anchorTagLessLang active'
        } else if (length <= 4) {
            classToggle = 'anchorTagLessLang'
        } else if (length > 4 && language === locale) {
            classToggle = 'anchorTag active'
        }
        return classToggle
    }

    const onLanguageClick = (lang: string) => {
        const language = lang.toUpperCase();
        Analytics.getInstance().logEvent(IdentifyLanguageClick(language));
        setIsToggleOpen(false);
    }

    return (
        <>
            <div className={isMobile ? "" : "mainContainerToggle"}>

                <style jsx>{`
                      .imgClass {
                        margin: 0 0 0 30px;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                      }

                      .dropdownContent {
                        font-family: var(--font-saira);
                        font-weight: 900;
                        font-size: 12px;
                        border-radius: 2px;
                        position: absolute;
                        background-color: black;
                        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                        z-index: 1;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        width: 228px;
                        margin-top: 0px;
                        left: 150px;
                        top: 50px;
                      }

                      .anchorTag {
                        color: white;
                        padding: 10px;
                        text-decoration: none;
                        display: block;
                        text-align: center;
                      }

                      .anchorTagLessLang {
                        color: white;
                        padding: 10px;
                        text-decoration: none;
                        display: block;
                        text-align: center;
                      }

                      .anchorTag:hover {
                        background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
                        border-radius: 2px;
                        color: black;
                      }

                      .anchorTagLessLang:hover {
                        background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
                        border-radius: 2px;
                        color: black;
                      }

                      .active {
                        background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
                        border-radius: 2px;
                        color: black !important;
                      }

                      .selectLang {
                        pointer-events: none;
                        background-color: black;
                        text-align: center;
                        color: white;
                        width: 228px;
                        margin: 15px 0;
                        font-size: 14px;
                      }

                      .drop {
                        position: absolute;
                        top: 50px
                      }

                      @media screen and (max-width: 767px) {

                        .imgClass {
                          margin: 0 2px;
                        }

                        .dropdownContent {
                          width: 100vw;
                          left: 0;
                        }

                        .selectLang {
                          width: 100vw;
                          margin: 10px 0;
                        }

                        .drop {
                          position: absolute;
                          left: 0px;
                        }
                      }

                    `
                }
                </style>
                {
                    <div className={'imgClass'} onClick={toggleClick}>
                        <CustomImageComponent
                            height={20}
                            width={20}
                            src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/langToggle.webp"
                            alt="winzo games toggle"
                            // priority={true}
                        />
                    </div>
                }

                        <div className="drop dropdownContent" style={{display: isToggleOpen ? 'flex' : 'none'}}>
                            <div className="selectLang">SELECT YOUR LANGUAGE</div>
                            {availableLanguages && availableLanguages.map((drop, index) => {
                                return (
                                    <CustomLinkComponent href={"/" + path}
                                                         locale={drop.locale}
                                                         className={locale === drop.locale ? `active` : ""} key={index}
                                                         desktopStyle={{
                                                             color: "white",
                                                             width: "50%",
                                                             textDecoration: "none",
                                                             display: "block",
                                                             textAlign: "center"
                                                         }}
                                                         mobStyle={{
                                                             color: "white",
                                                             width: `${getClassName(drop.locale).includes("anchorTagLessLang") ? "50%" : "20%"}`,
                                                             textDecoration: "none",
                                                             display: "block",
                                                             textAlign: "center"
                                                         }}
                                    >
                                        <span className={getClassName(drop.locale)}
                                              onClick={() => onLanguageClick(drop.locale)}
                                              key={index}>{drop.langugage}</span>
                                    </CustomLinkComponent>
                                )
                            })}
                        </div>
            </div>
        </>
    )
}

export default LanguageToggleComponent;