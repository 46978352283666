import { useState } from "react";

const useHandleHamburgerClick = (): [boolean, () => void] => {

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    // Function to toggle side drawer menu state
    const handleHamburgerClick = (): void => {
        if (menuOpen) {
            setMenuOpen(false);
        } else {
            setMenuOpen(true);
        }
    };

    return [menuOpen, handleHamburgerClick];
}

export default useHandleHamburgerClick;