import { IHamburgerComponentProps } from "../../../lib/types/components";

const HamburgerComponent = (props: IHamburgerComponentProps) => {

    const {menuOpen, handleHamburgerClick} = props;

    return (
        handleHamburgerClick ?
            <div
                onClick={() => handleHamburgerClick()}
                className={menuOpen ? "hamburgerContainer open" : "hamburgerContainer"}
            >
                <style jsx>{`
                    .hamburgerContainer{
                        width: 28px;
                        height: 22px;
                        position: relative;
                        transform: rotate(0);
                        transition: .5s ease-in-out;
                        cursor: pointer;
                        margin-left: 10px;
                        z-index: 999;
                    }
                    
                    .hamburgerContainer span{
                        display: block;
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        background: #fff;
                        border-radius: 2px;
                        opacity: 1;
                        left: 0;
                        transform: rotate(0);
                        transition: .25s ease-in-out;
                    }
                    
                    .hamburgerContainer span:nth-child(1){
                        top: 0;
                    }
                    
                    .hamburgerContainer span:nth-child(2),.hamburgerContainer span:nth-child(3){
                        top:10px;
                    }
                    
                    .hamburgerContainer span:nth-child(4){
                        top: 20px;
                    }
                    
                    .open span:nth-child(1) {
                        top: 10px;
                        width: 0;
                        left: 50%;
                    } 
                    
                    .open span:nth-child(2){
                        transform: rotate(45deg);
                    }
                    
                    .open span:nth-child(3){
                        transform: rotate(-45deg);
                    }
                    
                    .open span:nth-child(4){
                        top: 10px;
                        width: 0;
                        left: 50%;
                    }
            `}
                </style>
                <span/>
                <span/>
                <span/>
                <span/>
            </div>
            : <></>
    )
}

export default HamburgerComponent;